import {IBackToUrl} from "../reducers/back_to_url_reducer";

export const BACK_TO = "navigation/BACK_TO";

export interface IBackToUrlAction {
    type: typeof BACK_TO;
    payload: IBackToUrl;
}

export const backToUrl = (url: string, text: string): IBackToUrlAction => ({type: BACK_TO, payload: {url, text}});
import {IBackToUrl} from "../reducers/back_to_url_reducer";

export const BACK_TO = "navigation/BACK_TO";

export interface IBackToUrlAction {
    type: typeof BACK_TO;
    payload: IBackToUrl;
}

export const backToUrl = (url: string, text: string): IBackToUrlAction => ({type: BACK_TO, payload: {url, text}});
