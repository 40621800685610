import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IPaginatedResponse} from "../../request/IPaginatedResponse";
import {IReelListItem} from "../types/IReelListItem";

type IRequestParams =
    | {
          regionIds?: number[];
          offerId?: never;
      }
    | {
          offerId?: number;
          regionIds?: never;
      };

export const getReelsList = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getReelsList: build.query<IPaginatedResponse<IReelListItem>, IRequestParams>({
            query: (params) => {
                const {regionIds, offerId} = params;

                return {
                    url: apiV2ListLink.reels.list(Scenario.REEL_LIST),
                    params: offerId
                        ? {
                              display_in_offer: offerId
                          }
                        : {
                              without_region: true,
                              ...(regionIds && regionIds.length > 0 ? {region: regionIds} : {})
                          }
                };
            }
        })
    })
});

export const {useGetReelsListQuery} = getReelsList;
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IPaginatedResponse} from "../../request/IPaginatedResponse";
import {IReelListItem} from "../types/IReelListItem";

type IRequestParams =
    | {
          regionIds?: number[];
          offerId?: never;
      }
    | {
          offerId?: number;
          regionIds?: never;
      };

export const getReelsList = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getReelsList: build.query<IPaginatedResponse<IReelListItem>, IRequestParams>({
            query: (params) => {
                const {regionIds, offerId} = params;

                return {
                    url: apiV2ListLink.reels.list(Scenario.REEL_LIST),
                    params: offerId
                        ? {
                              display_in_offer: offerId
                          }
                        : {
                              without_region: true,
                              ...(regionIds && regionIds.length > 0 ? {region: regionIds} : {})
                          }
                };
            }
        })
    })
});

export const {useGetReelsListQuery} = getReelsList;
