import {useEffect, useState} from "react";
import {Controller} from "@react-spring/web";

import {Animated} from "../../atoms/Animated";

export const useAnimatedHomepageHeader = (slides: string[]) => {
    const [index, setIndex] = useState(0);

    const controller = new Controller({from: {opacity: 0}, config: {duration: 1000}});

    useEffect(() => {
        const timeout = setTimeout(() => {
            clearTimeout(timeout);
            setIndex((state) => (state + 1) % slides.length);
        }, 6000);
    }, [index]);

    useEffect(() => {
        controller.start({to: {opacity: 1}});
    });

    return <Animated style={controller.springs}>{slides[index]}</Animated>;
};
import {useEffect, useState} from "react";
import {Controller} from "@react-spring/web";

import {Animated} from "../../atoms/Animated";

export const useAnimatedHomepageHeader = (slides: string[]) => {
    const [index, setIndex] = useState(0);

    const controller = new Controller({from: {opacity: 0}, config: {duration: 1000}});

    useEffect(() => {
        const timeout = setTimeout(() => {
            clearTimeout(timeout);
            setIndex((state) => (state + 1) % slides.length);
        }, 6000);
    }, [index]);

    useEffect(() => {
        controller.start({to: {opacity: 1}});
    });

    return <Animated style={controller.springs}>{slides[index]}</Animated>;
};
