export const APPLICATION_LOCAL_STORAGE_KEY = "applicationFormSavedInfo";
export const USER_LAST_ACTIVITY = "user_last_activity";
export const OFFER_SEARCH_LS_KEY = "offerSearch";
export const OTHER_PROPERTIES_ROOMS_FILTER = "otherPropertiesRoomFilter";
export const IS_COOKIE_MESSAGE_READ_LS_KEY = "cookieMessageIsRead";
export const SENT_APPLICATIONS = "sentApplications";
export const SENT_PROPERTY_APPLICATIONS = "sentPropertyApplications";
export const IS_FAVOURITES_TOOLTIP_CLOSED = "favouritesTooltipClosed";
export const FAVOURITE_LOCAL_STORAGE_KEY_V2 = "favourite_storage_v2";
export const FAVOURITE_LOCAL_STORAGE_KEY_V3 = "favourite_storage_v3";
export const IS_FAVOURITES_MODAL_CLOSED = "favouritesModalClosed";
export const TWO_PERCENT_CREDIT_FILTER_STATE = "twoPercentFilterState";
export const PROPERTY_CONFIGURATOR_VALUES = "propertyConfiguratorValues";
export const PROPERTY_CONFIGURATOR_LOCATION = "propertyConfiguratorLocation";
export const OFFER_CHATBOT_STORAGE_KEY = "offerChatBot";
export const HAS_RECOMMENDATION_POPOVER_BEEN_CLOSED = "hasRecommendationPopoverBeenClosed";
export const CLIENT_TYPE_QUESTIONNAIRE_FILLED = "clientTypeQuestionnaireFilled";
export const SHOW_PUBLIC_TRANSPORT_ON_MAP = "mapPublicTransport";
export const MID_LISTING_LAST_BANNER_TYPE = "midListingLastBannerType";
export const USER_LAST_SEEN_OFFER_PROPERTIES = "userLastSeenOfferProperties";
export const OFFER_OPINIONS_SENT_LS_KEY = "offerOpinionsSent";
export const ARTICLE_ATTACHMENT_LS_KEY = "articleAttachment";

export const USER_REEL_PLAYER_SETTINGS = "userReelPlayerSettings";
export const USER_REEL_STATS = "userReelStats";
export const ASKED_ABOUT_LOCATION = "askedAboutLocation";
export const BOOSTER_STATS = "boosterStats";
export const SEARCH_GUIDE_TOUR_STATE = "searchGuideTourState";
export const APPLICATION_LOCAL_STORAGE_KEY = "applicationFormSavedInfo";
export const USER_LAST_ACTIVITY = "user_last_activity";
export const OFFER_SEARCH_LS_KEY = "offerSearch";
export const OTHER_PROPERTIES_ROOMS_FILTER = "otherPropertiesRoomFilter";
export const IS_COOKIE_MESSAGE_READ_LS_KEY = "cookieMessageIsRead";
export const SENT_APPLICATIONS = "sentApplications";
export const SENT_PROPERTY_APPLICATIONS = "sentPropertyApplications";
export const IS_FAVOURITES_TOOLTIP_CLOSED = "favouritesTooltipClosed";
export const FAVOURITE_LOCAL_STORAGE_KEY_V2 = "favourite_storage_v2";
export const FAVOURITE_LOCAL_STORAGE_KEY_V3 = "favourite_storage_v3";
export const IS_FAVOURITES_MODAL_CLOSED = "favouritesModalClosed";
export const TWO_PERCENT_CREDIT_FILTER_STATE = "twoPercentFilterState";
export const PROPERTY_CONFIGURATOR_VALUES = "propertyConfiguratorValues";
export const PROPERTY_CONFIGURATOR_LOCATION = "propertyConfiguratorLocation";
export const OFFER_CHATBOT_STORAGE_KEY = "offerChatBot";
export const HAS_RECOMMENDATION_POPOVER_BEEN_CLOSED = "hasRecommendationPopoverBeenClosed";
export const CLIENT_TYPE_QUESTIONNAIRE_FILLED = "clientTypeQuestionnaireFilled";
export const SHOW_PUBLIC_TRANSPORT_ON_MAP = "mapPublicTransport";
export const MID_LISTING_LAST_BANNER_TYPE = "midListingLastBannerType";
export const USER_LAST_SEEN_OFFER_PROPERTIES = "userLastSeenOfferProperties";
export const OFFER_OPINIONS_SENT_LS_KEY = "offerOpinionsSent";
export const ARTICLE_ATTACHMENT_LS_KEY = "articleAttachment";

export const USER_REEL_PLAYER_SETTINGS = "userReelPlayerSettings";
export const USER_REEL_STATS = "userReelStats";
export const ASKED_ABOUT_LOCATION = "askedAboutLocation";
export const BOOSTER_STATS = "boosterStats";
export const SEARCH_GUIDE_TOUR_STATE = "searchGuideTourState";
