export const regionDefaultOption = {value: 8647, label: "Warszawa", slug: "warszawa"};

export const regionOptions = [
    regionDefaultOption,
    {value: 11158, label: "Kraków", slug: "krakow"},
    {value: 26955, label: "Wrocław", slug: "wroclaw"},
    {value: 30892, label: "Poznań", slug: "poznan"},
    {value: 46083, label: "Gdańsk", slug: "gdansk"},
    {value: 16187, label: "Łódź", slug: "lodz"},
    {value: 36190, label: "Kielce", slug: "kielce"},
    {value: 13220, label: "Lublin", slug: "lublin"},
    {value: 22550, label: "Bydgoszcz", slug: "bydgoszcz"}
];

export enum SortValue {
    PRICE_M2 = "price_m2",
    RATING = "rating",
    CREATE_DATE = "create_date",
    CONSTRUCTION_DATE_RANGE = "construction_date_range",
    PROMOTED = "promote"
}

export const sortDefaultOption = {value: SortValue.PRICE_M2, title: "Najlepsza cena za m2"};

export const sortOptions = [
    sortDefaultOption,
    {value: SortValue.RATING, title: "Najlepiej oceniane"},
    {value: SortValue.CREATE_DATE, title: "Najnowsze"},
    {value: SortValue.CONSTRUCTION_DATE_RANGE, title: "Gotowe do odbioru"},
    {value: SortValue.PROMOTED, title: "Oferty promocyjne"}
];
export const regionDefaultOption = {value: 8647, label: "Warszawa", slug: "warszawa"};

export const regionOptions = [
    regionDefaultOption,
    {value: 11158, label: "Kraków", slug: "krakow"},
    {value: 26955, label: "Wrocław", slug: "wroclaw"},
    {value: 30892, label: "Poznań", slug: "poznan"},
    {value: 46083, label: "Gdańsk", slug: "gdansk"},
    {value: 16187, label: "Łódź", slug: "lodz"},
    {value: 36190, label: "Kielce", slug: "kielce"},
    {value: 13220, label: "Lublin", slug: "lublin"},
    {value: 22550, label: "Bydgoszcz", slug: "bydgoszcz"}
];

export enum SortValue {
    PRICE_M2 = "price_m2",
    RATING = "rating",
    CREATE_DATE = "create_date",
    CONSTRUCTION_DATE_RANGE = "construction_date_range",
    PROMOTED = "promote"
}

export const sortDefaultOption = {value: SortValue.PRICE_M2, title: "Najlepsza cena za m2"};

export const sortOptions = [
    sortDefaultOption,
    {value: SortValue.RATING, title: "Najlepiej oceniane"},
    {value: SortValue.CREATE_DATE, title: "Najnowsze"},
    {value: SortValue.CONSTRUCTION_DATE_RANGE, title: "Gotowe do odbioru"},
    {value: SortValue.PROMOTED, title: "Oferty promocyjne"}
];
