import React, {ReactNode} from "react";
import {css, Theme} from "@emotion/react";

import {IRange} from "@pg-design/inputs-module";
import {head} from "@pg-mono/nodash";
import {decimalSeparatorThousand} from "@pg-mono/string-utils";

import {getAreaFilterLabelText} from "../../real_estate/utils/filter_labels";
import {constructionEndDateOptions} from "../components/ConstructionEndDateSwitch";
import {floorOptions} from "../components/FloorChoicesSwitch";

const getRangeInfo = (value: IRange<string>, unit: ReactNode) => {
    const {lower, upper} = value;

    if (lower && upper) {
        return (
            <>
                {`${decimalSeparatorThousand(lower)} - ${decimalSeparatorThousand(upper)}`}&nbsp;{unit}
            </>
        );
    }
    if (lower) {
        return (
            <>
                {`od ${decimalSeparatorThousand(lower)}`}&nbsp;{unit}
            </>
        );
    }
    if (upper) {
        return (
            <>
                {`do ${decimalSeparatorThousand(upper)}`}&nbsp;{unit}
            </>
        );
    }

    return "";
};

export const getAreaFilterLabel = (value: IRange<string>, defaultLabel: string) => {
    const {label, text} = getAreaFilterLabelText(value, defaultLabel);

    if (text) {
        return (
            <React.Fragment>
                {label}
                <br />
                <span css={subLabel}>
                    {text}&nbsp;
                    <span className="nowrap">
                        m<sup>2</sup>
                    </span>
                </span>
            </React.Fragment>
        );
    }

    return label;
};

export const getPriceM2FilterLabel = (value: IRange<string>, label: string) => {
    const {lower, upper} = value;

    if (lower && upper) {
        return (
            <React.Fragment>
                {`${decimalSeparatorThousand(lower)} - ${decimalSeparatorThousand(upper)}`}&nbsp;
                <span className="nowrap">
                    zł/m<sup>2</sup>
                </span>
            </React.Fragment>
        );
    }
    if (lower) {
        return (
            <React.Fragment>
                {`od ${decimalSeparatorThousand(lower)}`}&nbsp;
                <span className="nowrap">
                    zł/m<sup>2</sup>
                </span>
            </React.Fragment>
        );
    }
    if (upper) {
        return (
            <React.Fragment>
                {`do ${decimalSeparatorThousand(upper)}`}&nbsp;
                <span className="nowrap">
                    zł/m<sup>2</sup>
                </span>
            </React.Fragment>
        );
    }
    return label;
};

export const getPriceFilterLabel = (value: IRange<string>, label: string, hideLabelOnValue?: boolean) => {
    const {lower, upper} = value;

    if (!lower && !upper) {
        return label;
    }

    return hideLabelOnValue ? (
        getRangeInfo(value, "zł")
    ) : (
        <>
            Cena <br />
            <span css={subLabel}>{getRangeInfo(value, "zł")}</span>
        </>
    );
};

export const getFloorChoiceLabel = (value?: number[]) => {
    const floorChoiceVerbose = value && floorOptions.map((floor) => floor.value === head(value) && floor.label);

    if (value && value.length > 1) {
        return (
            <React.Fragment>
                Piętra <br />
                <span css={subLabel}>+{value.length}</span>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            Piętra <br />
            <span css={subLabel}>{floorChoiceVerbose}</span>
        </React.Fragment>
    );
};

export const getConstructionEndDateLabel = (value?: number[], hideLabelOnValue?: boolean): string | ReactNode => {
    const constructionEndDateVerbose = value && constructionEndDateOptions.map((date) => date.value == head(value) && date.label).filter(Boolean)?.[0];

    return hideLabelOnValue && constructionEndDateVerbose ? (
        constructionEndDateVerbose
    ) : (
        <React.Fragment>
            Termin oddania
            <br />
            <span css={constructionEndDateSubLabel}>{constructionEndDateVerbose}</span>
        </React.Fragment>
    );
};

const subLabel = (theme: Theme) => css`
    font-size: ${theme.globals.text.root};
    color: ${theme.colors.gray[700]};
`;

//first construction end date option is too long and spoil the UI
const constructionEndDateSubLabel = (theme: Theme) => css`
    font-size: 0.9rem;
    color: ${theme.colors.gray[700]};
`;
import React, {ReactNode} from "react";
import {css, Theme} from "@emotion/react";

import {IRange} from "@pg-design/inputs-module";
import {head} from "@pg-mono/nodash";
import {decimalSeparatorThousand} from "@pg-mono/string-utils";

import {getAreaFilterLabelText} from "../../real_estate/utils/filter_labels";
import {constructionEndDateOptions} from "../components/ConstructionEndDateSwitch";
import {floorOptions} from "../components/FloorChoicesSwitch";

const getRangeInfo = (value: IRange<string>, unit: ReactNode) => {
    const {lower, upper} = value;

    if (lower && upper) {
        return (
            <>
                {`${decimalSeparatorThousand(lower)} - ${decimalSeparatorThousand(upper)}`}&nbsp;{unit}
            </>
        );
    }
    if (lower) {
        return (
            <>
                {`od ${decimalSeparatorThousand(lower)}`}&nbsp;{unit}
            </>
        );
    }
    if (upper) {
        return (
            <>
                {`do ${decimalSeparatorThousand(upper)}`}&nbsp;{unit}
            </>
        );
    }

    return "";
};

export const getAreaFilterLabel = (value: IRange<string>, defaultLabel: string) => {
    const {label, text} = getAreaFilterLabelText(value, defaultLabel);

    if (text) {
        return (
            <React.Fragment>
                {label}
                <br />
                <span css={subLabel}>
                    {text}&nbsp;
                    <span className="nowrap">
                        m<sup>2</sup>
                    </span>
                </span>
            </React.Fragment>
        );
    }

    return label;
};

export const getPriceM2FilterLabel = (value: IRange<string>, label: string) => {
    const {lower, upper} = value;

    if (lower && upper) {
        return (
            <React.Fragment>
                {`${decimalSeparatorThousand(lower)} - ${decimalSeparatorThousand(upper)}`}&nbsp;
                <span className="nowrap">
                    zł/m<sup>2</sup>
                </span>
            </React.Fragment>
        );
    }
    if (lower) {
        return (
            <React.Fragment>
                {`od ${decimalSeparatorThousand(lower)}`}&nbsp;
                <span className="nowrap">
                    zł/m<sup>2</sup>
                </span>
            </React.Fragment>
        );
    }
    if (upper) {
        return (
            <React.Fragment>
                {`do ${decimalSeparatorThousand(upper)}`}&nbsp;
                <span className="nowrap">
                    zł/m<sup>2</sup>
                </span>
            </React.Fragment>
        );
    }
    return label;
};

export const getPriceFilterLabel = (value: IRange<string>, label: string, hideLabelOnValue?: boolean) => {
    const {lower, upper} = value;

    if (!lower && !upper) {
        return label;
    }

    return hideLabelOnValue ? (
        getRangeInfo(value, "zł")
    ) : (
        <>
            Cena <br />
            <span css={subLabel}>{getRangeInfo(value, "zł")}</span>
        </>
    );
};

export const getFloorChoiceLabel = (value?: number[]) => {
    const floorChoiceVerbose = value && floorOptions.map((floor) => floor.value === head(value) && floor.label);

    if (value && value.length > 1) {
        return (
            <React.Fragment>
                Piętra <br />
                <span css={subLabel}>+{value.length}</span>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            Piętra <br />
            <span css={subLabel}>{floorChoiceVerbose}</span>
        </React.Fragment>
    );
};

export const getConstructionEndDateLabel = (value?: number[], hideLabelOnValue?: boolean): string | ReactNode => {
    const constructionEndDateVerbose = value && constructionEndDateOptions.map((date) => date.value == head(value) && date.label).filter(Boolean)?.[0];

    return hideLabelOnValue && constructionEndDateVerbose ? (
        constructionEndDateVerbose
    ) : (
        <React.Fragment>
            Termin oddania
            <br />
            <span css={constructionEndDateSubLabel}>{constructionEndDateVerbose}</span>
        </React.Fragment>
    );
};

const subLabel = (theme: Theme) => css`
    font-size: ${theme.globals.text.root};
    color: ${theme.colors.gray[700]};
`;

//first construction end date option is too long and spoil the UI
const constructionEndDateSubLabel = (theme: Theme) => css`
    font-size: 0.9rem;
    color: ${theme.colors.gray[700]};
`;
