import {Controller, Form, FormProvider, useController, useFieldArray, useForm, useFormContext, useFormState, useWatch} from "react-hook-form";

/**
 * Import workarounds (webpack causes issues with current monorepo config, need to import hooks from this package
 * instead directly from lib)
 */
export const useFormRHF = useForm;
export const FormRHF = Form;
export const useControllerRHF = useController;
export const ControllerRHF = Controller;
export const useFormContextRHF = useFormContext;
export const FormProviderRHF = FormProvider;
export const useWatchRHF = useWatch;
export const useFormStateRHF = useFormState;
export const useFieldArrayRHF = useFieldArray;
import {Controller, Form, FormProvider, useController, useFieldArray, useForm, useFormContext, useFormState, useWatch} from "react-hook-form";

/**
 * Import workarounds (webpack causes issues with current monorepo config, need to import hooks from this package
 * instead directly from lib)
 */
export const useFormRHF = useForm;
export const FormRHF = Form;
export const useControllerRHF = useController;
export const ControllerRHF = Controller;
export const useFormContextRHF = useFormContext;
export const FormProviderRHF = FormProvider;
export const useWatchRHF = useWatch;
export const useFormStateRHF = useFormState;
export const useFieldArrayRHF = useFieldArray;
