/*
 * This file is generated by generate_webpack_entries.ts
 */
import {rpAppPath} from "@pg-mono/rp-routes";

import {rpClient} from "../../../client/rp_client";
import {HomepageView} from "../../homepage/views/HomepageView";
const route = [{webpackEntry: "home", path: rpAppPath.base, component: HomepageView}];

(function () {
    rpClient(route);
})();
/*
 * This file is generated by generate_webpack_entries.ts
 */
import {rpAppPath} from "@pg-mono/rp-routes";

import {rpClient} from "../../../client/rp_client";
import {HomepageView} from "../../homepage/views/HomepageView";
const route = [{webpackEntry: "home", path: rpAppPath.base, component: HomepageView}];

(function () {
    rpClient(route);
})();
