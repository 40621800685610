import {hitGoogleTagManager} from "./init_google_tag_manager";

interface GAEvent {
    event: "GAEvent";
    GAEventCategory: "ui";
    GAEventAction: "filtry" | "listing - liczba ofert" | "sortowanie";
    GAEventLabel: string;
    GAEventNoInteraction: true;
}

// OfferDetail View -> property list search
export const gtmEventPropertyListSearch = (field: string) => {
    const gtmData: GAEvent = {
        event: "GAEvent",
        GAEventCategory: "ui",
        GAEventAction: "filtry",
        GAEventLabel: field,
        GAEventNoInteraction: true
    };
    hitGoogleTagManager(gtmData);
};

// OfferList View -> offer list search
export const gtmEventOfferListSearch = (field: string) => {
    const gtmData: GAEvent = {
        event: "GAEvent",
        GAEventCategory: "ui",
        GAEventAction: "filtry",
        GAEventLabel: field,
        GAEventNoInteraction: true
    };
    hitGoogleTagManager(gtmData);
};

// OfferList View -> offer list search
export const gtmEventOfferListSort = (sortType: string) => {
    const gtmData: GAEvent = {
        event: "GAEvent",
        GAEventCategory: "ui",
        GAEventAction: "sortowanie",
        GAEventLabel: sortType,
        GAEventNoInteraction: true
    };
    hitGoogleTagManager(gtmData);
};

// OfferList/OfferListMap View -> offers count
export const gtmEventOffersCount = (offersCount: number) => {
    const gtmData: GAEvent = {
        event: "GAEvent",
        GAEventCategory: "ui",
        GAEventAction: "listing - liczba ofert",
        GAEventLabel: offersCount.toString(),
        GAEventNoInteraction: true
    };
    hitGoogleTagManager(gtmData);
};
import {hitGoogleTagManager} from "./init_google_tag_manager";

interface GAEvent {
    event: "GAEvent";
    GAEventCategory: "ui";
    GAEventAction: "filtry" | "listing - liczba ofert" | "sortowanie";
    GAEventLabel: string;
    GAEventNoInteraction: true;
}

// OfferDetail View -> property list search
export const gtmEventPropertyListSearch = (field: string) => {
    const gtmData: GAEvent = {
        event: "GAEvent",
        GAEventCategory: "ui",
        GAEventAction: "filtry",
        GAEventLabel: field,
        GAEventNoInteraction: true
    };
    hitGoogleTagManager(gtmData);
};

// OfferList View -> offer list search
export const gtmEventOfferListSearch = (field: string) => {
    const gtmData: GAEvent = {
        event: "GAEvent",
        GAEventCategory: "ui",
        GAEventAction: "filtry",
        GAEventLabel: field,
        GAEventNoInteraction: true
    };
    hitGoogleTagManager(gtmData);
};

// OfferList View -> offer list search
export const gtmEventOfferListSort = (sortType: string) => {
    const gtmData: GAEvent = {
        event: "GAEvent",
        GAEventCategory: "ui",
        GAEventAction: "sortowanie",
        GAEventLabel: sortType,
        GAEventNoInteraction: true
    };
    hitGoogleTagManager(gtmData);
};

// OfferList/OfferListMap View -> offers count
export const gtmEventOffersCount = (offersCount: number) => {
    const gtmData: GAEvent = {
        event: "GAEvent",
        GAEventCategory: "ui",
        GAEventAction: "listing - liczba ofert",
        GAEventLabel: offersCount.toString(),
        GAEventNoInteraction: true
    };
    hitGoogleTagManager(gtmData);
};
