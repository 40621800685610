import {hitAlgolytics} from "@pg-mono/algolytics";
import {IAppError} from "@pg-mono/request-state";

import {ApplicationSource, ApplicationSourceSection, getApplicationSourceDisplay} from "../../application/utils/ApplicationSource";
import {IHitApplicationData} from "../../lead/types/IHitApplicationData";
import {ViewType} from "../../view_type/ViewType";
import {getTrackedOfferData, ITrackedOffer} from "./tracked_utils/tracked_offer";
import {getTrackedPropertyData, ITrackedProperty} from "./tracked_utils/tracked_property";
import {getTrackedSiteData} from "./tracked_utils/tracked_site";
import {getTrackedVendorData, ITrackedVendor} from "./tracked_utils/tracked_vendor";

export interface IApplicationExtraPayload {
    recommendation?: boolean;
    recommendedProperty?: boolean;
    push?: boolean;
    my_offer_list?: boolean;
    extraPayloadViewType?: ViewType;
    variant?: number;
    appError?: IAppError | null;
    rooms?: number[];
}

/**
 * Hit functions
 */

const applicationHit =
    (trackEvent: string) =>
    (
        application: ITrackedApplication,
        applicationSourceSection: ApplicationSourceSection | null,
        viewType: ViewType | null,
        extraPayload: IApplicationExtraPayload,
        vendor?: ITrackedVendor,
        offer?: ITrackedOffer,
        property?: ITrackedProperty
    ) => {
        const payload = {
            view_type: viewType,
            source_section: applicationSourceSection != null ? applicationSourceSection : undefined,
            ...getTrackedSiteData(),
            ...getTrackedApplicationData(application),
            ...(vendor ? getTrackedVendorData(vendor) : {}),
            ...(offer ? getTrackedOfferData(offer) : {}),
            ...(property ? getTrackedPropertyData(property) : {}),
            ...extraPayload
        };
        hitAlgolytics(trackEvent, payload);
    };

const applicationHitWithoutData =
    (trackEvent: string) =>
    (application: ITrackedApplication, applicationSourceSection: ApplicationSourceSection | null, viewType: ViewType | null, variant?: number) => {
        const payload = {
            view_type: viewType,
            source_section: applicationSourceSection != null ? applicationSourceSection : undefined,
            variant,
            ...getTrackedSiteData(),
            ...getTrackedApplicationData(application)
        };
        hitAlgolytics(trackEvent, payload);
    };

const applicationSourceHit =
    (trackEvent: string) =>
    (
        applicationSource: ApplicationSource,
        applicationSourceSection: ApplicationSourceSection | null,
        viewType: ViewType | null,
        extraPayload: IApplicationExtraPayload = {},
        vendor?: ITrackedVendor,
        offer?: ITrackedOffer,
        property?: ITrackedProperty
    ) => {
        const payload = {
            view_type: viewType,
            ...getTrackedSiteData(),
            source: getApplicationSourceDisplay(applicationSource),
            source_section: applicationSourceSection != null ? applicationSourceSection : undefined,
            source_id: applicationSource,
            ...(vendor ? getTrackedVendorData(vendor) : {}),
            ...(offer ? getTrackedOfferData(offer) : {}),
            ...(property ? getTrackedPropertyData(property) : {}),
            ...extraPayload
        };
        hitAlgolytics(trackEvent, payload);
    };

export type IMultiApplicationViewTypes = "favourite_list" | "recommendation_list";
const applicationOnlyIdsHit =
    (trackEvent: string) =>
    (application: ITrackedApplication, viewType: IMultiApplicationViewTypes, vendorId: number, offerId?: number, propertyId?: number) => {
        const payload = {
            view_type: viewType,
            ...getTrackedSiteData(),
            ...getTrackedApplicationData(application),
            vendor_id: vendorId,
            offer_id: offerId,
            property_id: propertyId
        };
        hitAlgolytics(trackEvent, payload);
    };

export const applicationCancelHit = (trackEvent: string) => (applicationId: string) => {
    const payload = {
        ...getTrackedSiteData(),
        applicationId
    };

    hitAlgolytics(trackEvent, payload);
};

/**
 * Helper
 */
export interface ITrackedApplication {
    email: string;
    financing_services: boolean;
    name: string;
    phone: string;
    source: ApplicationSource;
    uuid: string;
}

export function getTrackedApplicationData(application: ITrackedApplication) {
    return {
        application_id: application.uuid,
        source: getApplicationSourceDisplay(application.source),
        source_id: application.source,
        financing_services: application.financing_services,
        email: application.email,
        name: application.name,
        phone: application.phone
    };
}

const getViewType = (viewType?: ViewType) => {
    return viewType ? viewType : null;
};

/**
 * Specific hit factories
 */

// full application
const createHitApplication =
    (trackEvent: string) =>
    (
        application: ITrackedApplication,
        applicationSourceSection: ApplicationSourceSection | null,
        data: IHitApplicationData,
        extraPayload: IApplicationExtraPayload = {}
    ) => {
        const {vendor, offer, property} = data;
        const trackedVendor = (vendor && vendor.detail) || undefined;
        const trackedOffer = (offer && offer.detail) || undefined;
        const trackedProperty = (property && property.detail) || undefined;
        const {extraPayloadViewType, ...restExtraPayload} = extraPayload;
        const viewType = getViewType(extraPayloadViewType);
        applicationHit(trackEvent)(application, applicationSourceSection, viewType, restExtraPayload, trackedVendor, trackedOffer, trackedProperty);
    };
export const hitApplicationSuccess = createHitApplication("application");
export const hitNotificationConsentSuccess = createHitApplication("notification_consent_success");
export const hitNotificationConsentView = createHitApplication("notification_consent_view");
export const hitSurveyView = createHitApplication("profile_survey_open");
// application source only
const createHitApplicationSource =
    (trackEvent: string) =>
    (
        applicationSource: ApplicationSource,
        applicationSourceSection: ApplicationSourceSection | null,
        data: IHitApplicationData,
        extraPayload: IApplicationExtraPayload = {}
    ) => {
        const {vendor, offer, property} = data;
        const trackedVendor = (vendor && vendor.detail) || undefined;
        const trackedOffer = (offer && offer.detail) || undefined;
        const trackedProperty = (property && property.detail) || undefined;
        const {extraPayloadViewType, ...restExtraPayload} = extraPayload;
        const viewType = getViewType(extraPayloadViewType);
        applicationSourceHit(trackEvent)(applicationSource, applicationSourceSection, viewType, restExtraPayload, trackedVendor, trackedOffer, trackedProperty);
    };
export const hitApplicationOpen = createHitApplicationSource("application_open");
// Not used anywhere, nor in frontend project. The one below.
export const hitApplicationInteraction = createHitApplicationSource("application_interaction");
export const hitApplicationError = createHitApplicationSource("application_error");
export const hitRotatingApplicationError = createHitApplicationSource("application_error_form_with_questions");

// full application without data
const createHitApplicationWithoutData =
    (trackEvent: string) =>
    (application: ITrackedApplication, applicationSourceSection: ApplicationSourceSection | null, extraPayload: IApplicationExtraPayload = {}) => {
        const {extraPayloadViewType, variant} = extraPayload;
        const viewType = getViewType(extraPayloadViewType);
        applicationHitWithoutData(trackEvent)(application, applicationSourceSection, viewType, variant);
    };
// Used for financing services only application
export const hitApplicationFinancingServicesSuccess = createHitApplicationWithoutData("application");
export const hitApplicationPropertyConsultingSuccess = createHitApplicationWithoutData("application");
export const hitApplicationInvestmentSuccess = createHitApplicationWithoutData("application");
export const hitApplicationRecommendedPropertiesSuccess = createHitApplicationWithoutData("application");
export const hitApplicationFavouritesSuccess = createHitApplicationWithoutData("application");

// application source only without data
const createHitApplicationSourceWithoutData =
    (trackEvent: string) =>
    (applicationSource: ApplicationSource, applicationSourceSection: ApplicationSourceSection, extraPayload: IApplicationExtraPayload = {}) => {
        const {extraPayloadViewType, ...restExtraPayload} = extraPayload;

        const viewType = getViewType(extraPayloadViewType);
        applicationSourceHit(trackEvent)(applicationSource, applicationSourceSection, viewType, restExtraPayload);
    };
export const hitApplicationFinancingServicesOpen = createHitApplicationSourceWithoutData("application_open");
export const hitApplicationFinancingServicesError = createHitApplicationSourceWithoutData("application_error");
export const hitApplicationPropertyConsultingOpen = createHitApplicationSourceWithoutData("application_open");
export const hitApplicationPropertyConsultingError = createHitApplicationSourceWithoutData("application_error");
export const hitApplicationInvestmentError = createHitApplicationSourceWithoutData("application_error");
export const hitApplicationRecommendedPropertiesError = createHitApplicationSourceWithoutData("application_error");
export const hitApplicationFavouriteOffersError = createHitApplicationSourceWithoutData("application_error");

// application ids only
export const hitApplicationOnlyIdsSuccess = applicationOnlyIdsHit("application");

// application cancel lead
const createHitApplicationCancel = (trackEvent: string) => (applicationId: string) => {
    applicationCancelHit(trackEvent)(applicationId);
};
export const hitApplicationCancelSuccess = createHitApplicationCancel("application_cancelled");
export const hitApplicationCancelLinkOpen = createHitApplicationCancel("cpl_consent_cancel_open");
export const hitApplicationCancelLinkSuccess = createHitApplicationCancel("cpl_consent_cancel");
import {hitAlgolytics} from "@pg-mono/algolytics";
import {IAppError} from "@pg-mono/request-state";

import {ApplicationSource, ApplicationSourceSection, getApplicationSourceDisplay} from "../../application/utils/ApplicationSource";
import {IHitApplicationData} from "../../lead/types/IHitApplicationData";
import {ViewType} from "../../view_type/ViewType";
import {getTrackedOfferData, ITrackedOffer} from "./tracked_utils/tracked_offer";
import {getTrackedPropertyData, ITrackedProperty} from "./tracked_utils/tracked_property";
import {getTrackedSiteData} from "./tracked_utils/tracked_site";
import {getTrackedVendorData, ITrackedVendor} from "./tracked_utils/tracked_vendor";

export interface IApplicationExtraPayload {
    recommendation?: boolean;
    recommendedProperty?: boolean;
    push?: boolean;
    my_offer_list?: boolean;
    extraPayloadViewType?: ViewType;
    variant?: number;
    appError?: IAppError | null;
    rooms?: number[];
}

/**
 * Hit functions
 */

const applicationHit =
    (trackEvent: string) =>
    (
        application: ITrackedApplication,
        applicationSourceSection: ApplicationSourceSection | null,
        viewType: ViewType | null,
        extraPayload: IApplicationExtraPayload,
        vendor?: ITrackedVendor,
        offer?: ITrackedOffer,
        property?: ITrackedProperty
    ) => {
        const payload = {
            view_type: viewType,
            source_section: applicationSourceSection != null ? applicationSourceSection : undefined,
            ...getTrackedSiteData(),
            ...getTrackedApplicationData(application),
            ...(vendor ? getTrackedVendorData(vendor) : {}),
            ...(offer ? getTrackedOfferData(offer) : {}),
            ...(property ? getTrackedPropertyData(property) : {}),
            ...extraPayload
        };
        hitAlgolytics(trackEvent, payload);
    };

const applicationHitWithoutData =
    (trackEvent: string) =>
    (application: ITrackedApplication, applicationSourceSection: ApplicationSourceSection | null, viewType: ViewType | null, variant?: number) => {
        const payload = {
            view_type: viewType,
            source_section: applicationSourceSection != null ? applicationSourceSection : undefined,
            variant,
            ...getTrackedSiteData(),
            ...getTrackedApplicationData(application)
        };
        hitAlgolytics(trackEvent, payload);
    };

const applicationSourceHit =
    (trackEvent: string) =>
    (
        applicationSource: ApplicationSource,
        applicationSourceSection: ApplicationSourceSection | null,
        viewType: ViewType | null,
        extraPayload: IApplicationExtraPayload = {},
        vendor?: ITrackedVendor,
        offer?: ITrackedOffer,
        property?: ITrackedProperty
    ) => {
        const payload = {
            view_type: viewType,
            ...getTrackedSiteData(),
            source: getApplicationSourceDisplay(applicationSource),
            source_section: applicationSourceSection != null ? applicationSourceSection : undefined,
            source_id: applicationSource,
            ...(vendor ? getTrackedVendorData(vendor) : {}),
            ...(offer ? getTrackedOfferData(offer) : {}),
            ...(property ? getTrackedPropertyData(property) : {}),
            ...extraPayload
        };
        hitAlgolytics(trackEvent, payload);
    };

export type IMultiApplicationViewTypes = "favourite_list" | "recommendation_list";
const applicationOnlyIdsHit =
    (trackEvent: string) =>
    (application: ITrackedApplication, viewType: IMultiApplicationViewTypes, vendorId: number, offerId?: number, propertyId?: number) => {
        const payload = {
            view_type: viewType,
            ...getTrackedSiteData(),
            ...getTrackedApplicationData(application),
            vendor_id: vendorId,
            offer_id: offerId,
            property_id: propertyId
        };
        hitAlgolytics(trackEvent, payload);
    };

export const applicationCancelHit = (trackEvent: string) => (applicationId: string) => {
    const payload = {
        ...getTrackedSiteData(),
        applicationId
    };

    hitAlgolytics(trackEvent, payload);
};

/**
 * Helper
 */
export interface ITrackedApplication {
    email: string;
    financing_services: boolean;
    name: string;
    phone: string;
    source: ApplicationSource;
    uuid: string;
}

export function getTrackedApplicationData(application: ITrackedApplication) {
    return {
        application_id: application.uuid,
        source: getApplicationSourceDisplay(application.source),
        source_id: application.source,
        financing_services: application.financing_services,
        email: application.email,
        name: application.name,
        phone: application.phone
    };
}

const getViewType = (viewType?: ViewType) => {
    return viewType ? viewType : null;
};

/**
 * Specific hit factories
 */

// full application
const createHitApplication =
    (trackEvent: string) =>
    (
        application: ITrackedApplication,
        applicationSourceSection: ApplicationSourceSection | null,
        data: IHitApplicationData,
        extraPayload: IApplicationExtraPayload = {}
    ) => {
        const {vendor, offer, property} = data;
        const trackedVendor = (vendor && vendor.detail) || undefined;
        const trackedOffer = (offer && offer.detail) || undefined;
        const trackedProperty = (property && property.detail) || undefined;
        const {extraPayloadViewType, ...restExtraPayload} = extraPayload;
        const viewType = getViewType(extraPayloadViewType);
        applicationHit(trackEvent)(application, applicationSourceSection, viewType, restExtraPayload, trackedVendor, trackedOffer, trackedProperty);
    };
export const hitApplicationSuccess = createHitApplication("application");
export const hitNotificationConsentSuccess = createHitApplication("notification_consent_success");
export const hitNotificationConsentView = createHitApplication("notification_consent_view");
export const hitSurveyView = createHitApplication("profile_survey_open");
// application source only
const createHitApplicationSource =
    (trackEvent: string) =>
    (
        applicationSource: ApplicationSource,
        applicationSourceSection: ApplicationSourceSection | null,
        data: IHitApplicationData,
        extraPayload: IApplicationExtraPayload = {}
    ) => {
        const {vendor, offer, property} = data;
        const trackedVendor = (vendor && vendor.detail) || undefined;
        const trackedOffer = (offer && offer.detail) || undefined;
        const trackedProperty = (property && property.detail) || undefined;
        const {extraPayloadViewType, ...restExtraPayload} = extraPayload;
        const viewType = getViewType(extraPayloadViewType);
        applicationSourceHit(trackEvent)(applicationSource, applicationSourceSection, viewType, restExtraPayload, trackedVendor, trackedOffer, trackedProperty);
    };
export const hitApplicationOpen = createHitApplicationSource("application_open");
// Not used anywhere, nor in frontend project. The one below.
export const hitApplicationInteraction = createHitApplicationSource("application_interaction");
export const hitApplicationError = createHitApplicationSource("application_error");
export const hitRotatingApplicationError = createHitApplicationSource("application_error_form_with_questions");

// full application without data
const createHitApplicationWithoutData =
    (trackEvent: string) =>
    (application: ITrackedApplication, applicationSourceSection: ApplicationSourceSection | null, extraPayload: IApplicationExtraPayload = {}) => {
        const {extraPayloadViewType, variant} = extraPayload;
        const viewType = getViewType(extraPayloadViewType);
        applicationHitWithoutData(trackEvent)(application, applicationSourceSection, viewType, variant);
    };
// Used for financing services only application
export const hitApplicationFinancingServicesSuccess = createHitApplicationWithoutData("application");
export const hitApplicationPropertyConsultingSuccess = createHitApplicationWithoutData("application");
export const hitApplicationInvestmentSuccess = createHitApplicationWithoutData("application");
export const hitApplicationRecommendedPropertiesSuccess = createHitApplicationWithoutData("application");
export const hitApplicationFavouritesSuccess = createHitApplicationWithoutData("application");

// application source only without data
const createHitApplicationSourceWithoutData =
    (trackEvent: string) =>
    (applicationSource: ApplicationSource, applicationSourceSection: ApplicationSourceSection, extraPayload: IApplicationExtraPayload = {}) => {
        const {extraPayloadViewType, ...restExtraPayload} = extraPayload;

        const viewType = getViewType(extraPayloadViewType);
        applicationSourceHit(trackEvent)(applicationSource, applicationSourceSection, viewType, restExtraPayload);
    };
export const hitApplicationFinancingServicesOpen = createHitApplicationSourceWithoutData("application_open");
export const hitApplicationFinancingServicesError = createHitApplicationSourceWithoutData("application_error");
export const hitApplicationPropertyConsultingOpen = createHitApplicationSourceWithoutData("application_open");
export const hitApplicationPropertyConsultingError = createHitApplicationSourceWithoutData("application_error");
export const hitApplicationInvestmentError = createHitApplicationSourceWithoutData("application_error");
export const hitApplicationRecommendedPropertiesError = createHitApplicationSourceWithoutData("application_error");
export const hitApplicationFavouriteOffersError = createHitApplicationSourceWithoutData("application_error");

// application ids only
export const hitApplicationOnlyIdsSuccess = applicationOnlyIdsHit("application");

// application cancel lead
const createHitApplicationCancel = (trackEvent: string) => (applicationId: string) => {
    applicationCancelHit(trackEvent)(applicationId);
};
export const hitApplicationCancelSuccess = createHitApplicationCancel("application_cancelled");
export const hitApplicationCancelLinkOpen = createHitApplicationCancel("cpl_consent_cancel_open");
export const hitApplicationCancelLinkSuccess = createHitApplicationCancel("cpl_consent_cancel");
