import {css} from "@emotion/react";
import {useInView} from "@react-spring/web";

export const pulseHeartbeatAnimation = (duration: string, iteration: string) => css`
    animation: heartbeat ${duration} ease-in-out ${iteration} both;

    @keyframes heartbeat {
        from {
            transform: scale(1);
            transform-origin: center center;
            animation-timing-function: ease-out;
        }
        10% {
            transform: scale(0.91);
            animation-timing-function: ease-in;
        }
        17% {
            transform: scale(0.98);
            animation-timing-function: ease-out;
        }
        33% {
            transform: scale(0.87);
            animation-timing-function: ease-in;
        }
        45% {
            transform: scale(1);
            animation-timing-function: ease-out;
        }
    }
`;

const buildArgs = (once?: boolean, rootMargin?: string) => {
    let args = {};
    if (once) {
        if (!!rootMargin) {
            args = {once: true, rootMargin: rootMargin};
        } else {
            args = {once: true};
        }
    }
    if (!!rootMargin && !once) {
        args = {rootMargin: rootMargin};
    }
    return args;
};

export const animationOpacityScale = (startOpacity: number, startScale: number, once?: boolean, rootMargin?: string) => {
    const args = buildArgs(once, rootMargin);
    const [ref, style] = useInView(
        () => ({
            from: {opacity: startOpacity, scale: startScale},
            to: {opacity: 1, scale: 1}
        }),
        args
    );

    return {ref, style};
};

export const animationOpacityMoveX = (startOpacity: number, startX: number | string, once?: boolean, rootMargin?: string) => {
    const args = buildArgs(once, rootMargin);
    const [ref, style] = useInView(
        () => ({
            from: {opacity: startOpacity, x: startX},
            to: {opacity: 1, x: typeof startX === "string" ? "0" : 0}
        }),
        args
    );

    return {ref, style};
};
import {css} from "@emotion/react";
import {useInView} from "@react-spring/web";

export const pulseHeartbeatAnimation = (duration: string, iteration: string) => css`
    animation: heartbeat ${duration} ease-in-out ${iteration} both;

    @keyframes heartbeat {
        from {
            transform: scale(1);
            transform-origin: center center;
            animation-timing-function: ease-out;
        }
        10% {
            transform: scale(0.91);
            animation-timing-function: ease-in;
        }
        17% {
            transform: scale(0.98);
            animation-timing-function: ease-out;
        }
        33% {
            transform: scale(0.87);
            animation-timing-function: ease-in;
        }
        45% {
            transform: scale(1);
            animation-timing-function: ease-out;
        }
    }
`;

const buildArgs = (once?: boolean, rootMargin?: string) => {
    let args = {};
    if (once) {
        if (!!rootMargin) {
            args = {once: true, rootMargin: rootMargin};
        } else {
            args = {once: true};
        }
    }
    if (!!rootMargin && !once) {
        args = {rootMargin: rootMargin};
    }
    return args;
};

export const animationOpacityScale = (startOpacity: number, startScale: number, once?: boolean, rootMargin?: string) => {
    const args = buildArgs(once, rootMargin);
    const [ref, style] = useInView(
        () => ({
            from: {opacity: startOpacity, scale: startScale},
            to: {opacity: 1, scale: 1}
        }),
        args
    );

    return {ref, style};
};

export const animationOpacityMoveX = (startOpacity: number, startX: number | string, once?: boolean, rootMargin?: string) => {
    const args = buildArgs(once, rootMargin);
    const [ref, style] = useInView(
        () => ({
            from: {opacity: startOpacity, x: startX},
            to: {opacity: 1, x: typeof startX === "string" ? "0" : 0}
        }),
        args
    );

    return {ref, style};
};
