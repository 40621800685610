import {truncate as _truncate} from "@pg-mono/nodash";
import {compact, uniq} from "@pg-mono/nodash";

export const truncate = (value: string, length: number) => {
    return _truncate(value, {
        length: length,
        separator: /,? +/,
        omission: String.fromCharCode(8230)
    });
};

export const betterJoin = (value: string[]) => value.filter((v: string) => !!v).join(", ");

export const nowrap = (value: string) => value.replace(/ /g, String.fromCharCode(160));

export const multiToSingleLineString = (value: string) => value.replace(/\n/g, "").replace(/  +/g, " ");

export const removeRedundantWhiteSpaces = (value: string): string => value.replace(/\s{2,}/g, " ").trim();

export const replaceSpaceWithNonBreakingSpace = (value: string): string => value.replace(/ /g, `\u00A0`);

export const formatRange = (value: unknown[]) => compact(uniq(value)).join(" - ");

export const decimalSeparatorThousand = (value: string): string => value.replace(/.(?=(?:.{3})+$)/g, `$&${String.fromCharCode(160)}`);
import {truncate as _truncate} from "@pg-mono/nodash";
import {compact, uniq} from "@pg-mono/nodash";

export const truncate = (value: string, length: number) => {
    return _truncate(value, {
        length: length,
        separator: /,? +/,
        omission: String.fromCharCode(8230)
    });
};

export const betterJoin = (value: string[]) => value.filter((v: string) => !!v).join(", ");

export const nowrap = (value: string) => value.replace(/ /g, String.fromCharCode(160));

export const multiToSingleLineString = (value: string) => value.replace(/\n/g, "").replace(/  +/g, " ");

export const removeRedundantWhiteSpaces = (value: string): string => value.replace(/\s{2,}/g, " ").trim();

export const replaceSpaceWithNonBreakingSpace = (value: string): string => value.replace(/ /g, `\u00A0`);

export const formatRange = (value: unknown[]) => compact(uniq(value)).join(" - ");

export const decimalSeparatorThousand = (value: string): string => value.replace(/.(?=(?:.{3})+$)/g, `$&${String.fromCharCode(160)}`);
