import {css, Theme} from "@emotion/react";

import {mb, w100} from "@pg-design/helpers-css";

export const tabsWrapper = (theme: Theme) => css`
    overflow-y: auto;
    ${mb(3)};
    ${w100};

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        width: 8rem;
        height: 100%;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        margin-bottom: 0;

        &::after {
            display: none;
        }
    }
`;
import {css, Theme} from "@emotion/react";

import {mb, w100} from "@pg-design/helpers-css";

export const tabsWrapper = (theme: Theme) => css`
    overflow-y: auto;
    ${mb(3)};
    ${w100};

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        width: 8rem;
        height: 100%;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        margin-bottom: 0;

        &::after {
            display: none;
        }
    }
`;
