import React from "react";
import {useSelector} from "react-redux";

import {BRAND_NAME} from "../../app/constants/company";
import {rpCommonUrl} from "../../app/read_rp_environment_variables";
import {IRPStore} from "../../app/rp_reducer";

const rpLogo = require("../../app/images/rp_logo_340x86.png");

export const SchemaHomepage: React.FunctionComponent = () => {
    const description = useSelector((store: IRPStore) => store.metaData.standardMetaData.description);

    const jsonHomepage = {
        "@context": "http://schema.org/",
        "@type": "Organization",
        name: `Oferty nowych mieszkań i domów na sprzedaż - ${BRAND_NAME}`,
        description,
        logo: `${rpCommonUrl}${rpLogo}`,
        url: rpCommonUrl,
        contactPoint: {
            "@type": "ContactPoint",
            telephone: "228256071",
            contactType: "customer service",
            areaServed: "PL",
            availableLanguage: "Polish"
        },
        sameAs: [
            "https://www.facebook.com/rynekpierwotnypl/",
            "https://twitter.com/rynekpierwotny/",
            "https://www.instagram.com/rynekpierwotny/",
            "https://www.youtube.com/channel/UCjJn84caCWMP5h-EE5BBv8w/",
            "https://www.linkedin.com/company/rynekpierwotny-com/"
        ]
    };

    return (
        <span>
            <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(jsonHomepage)}} />
        </span>
    );
};
import React from "react";
import {useSelector} from "react-redux";

import {BRAND_NAME} from "../../app/constants/company";
import {rpCommonUrl} from "../../app/read_rp_environment_variables";
import {IRPStore} from "../../app/rp_reducer";

const rpLogo = require("../../app/images/rp_logo_340x86.png");

export const SchemaHomepage: React.FunctionComponent = () => {
    const description = useSelector((store: IRPStore) => store.metaData.standardMetaData.description);

    const jsonHomepage = {
        "@context": "http://schema.org/",
        "@type": "Organization",
        name: `Oferty nowych mieszkań i domów na sprzedaż - ${BRAND_NAME}`,
        description,
        logo: `${rpCommonUrl}${rpLogo}`,
        url: rpCommonUrl,
        contactPoint: {
            "@type": "ContactPoint",
            telephone: "228256071",
            contactType: "customer service",
            areaServed: "PL",
            availableLanguage: "Polish"
        },
        sameAs: [
            "https://www.facebook.com/rynekpierwotnypl/",
            "https://twitter.com/rynekpierwotny/",
            "https://www.instagram.com/rynekpierwotny/",
            "https://www.youtube.com/channel/UCjJn84caCWMP5h-EE5BBv8w/",
            "https://www.linkedin.com/company/rynekpierwotny-com/"
        ]
    };

    return (
        <span>
            <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(jsonHomepage)}} />
        </span>
    );
};
