import {Dispatch} from "redux";

import {appendQueryString, catch400, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {OfferDisplayType} from "../../offer/helpers/OfferDisplayType";
import {OfferType} from "../../offer/types/OfferType";

const HOMEPAGE_OFFER_LIST = "homepage/offer_list/fetch";
export const fetchHomepageOfferListTypes = createRequestActionTypes(HOMEPAGE_OFFER_LIST);

export const HOMEPAGE_OFFERS_PANEL_LIST_PAGE_SIZE = 7;
export const HOMEPAGE_OFFERS_PANEL_LIST_PAGE = 1;

const offerListApiLink = apiV2ListLink.offer.list(Scenario.OFFER_LIST);

const offerListConstrains = {
    limited_presentation: "False",
    display_type: OfferDisplayType.FOR_SALE,
    for_sale: "True",
    show_on_listing: "True"
};

export const fetchHomepageOfferList = (query: {[key: string]: number | string}) => async (dispatch: Dispatch) => {
    dispatch({type: fetchHomepageOfferListTypes.start});

    const fullQuery = {
        distance: 0,
        ...offerListConstrains,
        page: HOMEPAGE_OFFERS_PANEL_LIST_PAGE,
        ...query,
        type: [OfferType.HOUSE, OfferType.FLAT],
        page_size: HOMEPAGE_OFFERS_PANEL_LIST_PAGE_SIZE
    };

    const url = appendQueryString(offerListApiLink, fullQuery);

    return getRequest({}, url)
        .then((response) => {
            const result = {
                count: response.count,
                offerList: response.results
            };

            dispatch({type: fetchHomepageOfferListTypes.success, result});
        })
        .catch(
            catch400((error) => {
                dispatch({type: fetchHomepageOfferListTypes.error, error});
            })
        );
};

export const resetHomepageOfferList = () => ({
    type: fetchHomepageOfferListTypes.reset
});
import {Dispatch} from "redux";

import {appendQueryString, catch400, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {OfferDisplayType} from "../../offer/helpers/OfferDisplayType";
import {OfferType} from "../../offer/types/OfferType";

const HOMEPAGE_OFFER_LIST = "homepage/offer_list/fetch";
export const fetchHomepageOfferListTypes = createRequestActionTypes(HOMEPAGE_OFFER_LIST);

export const HOMEPAGE_OFFERS_PANEL_LIST_PAGE_SIZE = 7;
export const HOMEPAGE_OFFERS_PANEL_LIST_PAGE = 1;

const offerListApiLink = apiV2ListLink.offer.list(Scenario.OFFER_LIST);

const offerListConstrains = {
    limited_presentation: "False",
    display_type: OfferDisplayType.FOR_SALE,
    for_sale: "True",
    show_on_listing: "True"
};

export const fetchHomepageOfferList = (query: {[key: string]: number | string}) => async (dispatch: Dispatch) => {
    dispatch({type: fetchHomepageOfferListTypes.start});

    const fullQuery = {
        distance: 0,
        ...offerListConstrains,
        page: HOMEPAGE_OFFERS_PANEL_LIST_PAGE,
        ...query,
        type: [OfferType.HOUSE, OfferType.FLAT],
        page_size: HOMEPAGE_OFFERS_PANEL_LIST_PAGE_SIZE
    };

    const url = appendQueryString(offerListApiLink, fullQuery);

    return getRequest({}, url)
        .then((response) => {
            const result = {
                count: response.count,
                offerList: response.results
            };

            dispatch({type: fetchHomepageOfferListTypes.success, result});
        })
        .catch(
            catch400((error) => {
                dispatch({type: fetchHomepageOfferListTypes.error, error});
            })
        );
};

export const resetHomepageOfferList = () => ({
    type: fetchHomepageOfferListTypes.reset
});
