export const slickSliderSettings = {
    speed: 500,
    centerMode: false,
    infinite: false,
    outerEdgeLimit: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
        {
            breakpoint: 1450,
            settings: {
                variableWidth: false,
                slidesToShow: 3.75,
                slidesToScroll: 3,
                arrows: true
            }
        },
        {
            breakpoint: 1390,
            settings: {
                variableWidth: false,
                slidesToShow: 3.5,
                slidesToScroll: 3,
                arrows: true
            }
        },
        {
            breakpoint: 1365,
            settings: {
                variableWidth: false,
                slidesToShow: 3.25,
                slidesToScroll: 3,
                arrows: true
            }
        },
        {
            breakpoint: 1188,
            settings: {
                variableWidth: false,
                slidesToShow: 3,
                slidesToScroll: 3,
                arrows: true
            }
        },
        {
            breakpoint: 1128,
            settings: {
                variableWidth: true,
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: true
            }
        },
        {
            breakpoint: 650,
            settings: {
                variableWidth: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true
            }
        },
        {
            breakpoint: 440,
            settings: {
                variableWidth: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true
            }
        }
    ]
};
export const slickSliderSettings = {
    speed: 500,
    centerMode: false,
    infinite: false,
    outerEdgeLimit: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
        {
            breakpoint: 1450,
            settings: {
                variableWidth: false,
                slidesToShow: 3.75,
                slidesToScroll: 3,
                arrows: true
            }
        },
        {
            breakpoint: 1390,
            settings: {
                variableWidth: false,
                slidesToShow: 3.5,
                slidesToScroll: 3,
                arrows: true
            }
        },
        {
            breakpoint: 1365,
            settings: {
                variableWidth: false,
                slidesToShow: 3.25,
                slidesToScroll: 3,
                arrows: true
            }
        },
        {
            breakpoint: 1188,
            settings: {
                variableWidth: false,
                slidesToShow: 3,
                slidesToScroll: 3,
                arrows: true
            }
        },
        {
            breakpoint: 1128,
            settings: {
                variableWidth: true,
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: true
            }
        },
        {
            breakpoint: 650,
            settings: {
                variableWidth: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true
            }
        },
        {
            breakpoint: 440,
            settings: {
                variableWidth: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true
            }
        }
    ]
};
