import {useEffect, useRef, useState} from "react";

export const useBoundingClientRect = <T extends HTMLElement>() => {
    const elementRef = useRef<T | null>(null);

    const [boundingClientRect, setBoundingClientRect] = useState<DOMRect | null>(null);

    useEffect(() => {
        if (elementRef.current) {
            setBoundingClientRect(elementRef.current.getBoundingClientRect());
        }
    }, [elementRef.current]);

    return {
        boundingClientRect,
        elementRef
    };
};
import {useEffect, useRef, useState} from "react";

export const useBoundingClientRect = <T extends HTMLElement>() => {
    const elementRef = useRef<T | null>(null);

    const [boundingClientRect, setBoundingClientRect] = useState<DOMRect | null>(null);

    useEffect(() => {
        if (elementRef.current) {
            setBoundingClientRect(elementRef.current.getBoundingClientRect());
        }
    }, [elementRef.current]);

    return {
        boundingClientRect,
        elementRef
    };
};
