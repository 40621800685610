import {useMemo} from "react";
import {useTheme} from "@emotion/react";

import {rpAppLink} from "@pg-mono/rp-routes";

const searchingDesktop = require("../images/blog_categories/searching_desktop.png");
const searchingMobile = require("../images/blog_categories/searching_mobile.png");
const afterPurchaseDesktop = require("../images/blog_categories/after_purchase_desktop.png");
const afterPurchaseMobile = require("../images/blog_categories/after_purchase_mobile.png");

export const useHomepageBlogCategories = () => {
    const theme = useTheme();

    return useMemo(() => {
        return [
            {
                title: "W trakcie poszukiwań",
                description:
                    "Podpowiemy Ci, jak sprawnie i efektywnie poruszać się na rynku nieruchomości. Poznaj praktyczne wskazówki dotyczące kupowania mieszkania. Korzystaj z uporządkowanej wiedzy o rynku mieszkaniowym i kredytach. Sprawdź formalności związane z zakupem.",
                pictureSources: [
                    {minWidthPX: 0, src: searchingMobile, height: 296, width: 336},
                    {minWidthPX: 336, src: searchingDesktop, height: 296, width: 552}
                ],
                link: rpAppLink.article.category({categorySlug: "szukam-mieszkania"})
            },
            {
                title: "Po zakupie mieszkania",
                description:
                    "Zainspiruj się praktycznymi wskazówkami dotyczącymi urządzenia mieszkania. Dowiedz się na co zwrócić uwagę przy wyborze usług wykończenia wnętrz, przeprowadzek. Podpowiemy Ci jak mieszkać wygodnie.",
                pictureSources: [
                    {minWidthPX: 0, src: afterPurchaseMobile, height: 296, width: 336},
                    {minWidthPX: 336, src: afterPurchaseDesktop, height: 296, width: 552}
                ],
                link: rpAppLink.article.category({categorySlug: "mam-mieszkanie"})
            }
        ];
    }, [theme]);
};
import {useMemo} from "react";
import {useTheme} from "@emotion/react";

import {rpAppLink} from "@pg-mono/rp-routes";

const searchingDesktop = require("../images/blog_categories/searching_desktop.png");
const searchingMobile = require("../images/blog_categories/searching_mobile.png");
const afterPurchaseDesktop = require("../images/blog_categories/after_purchase_desktop.png");
const afterPurchaseMobile = require("../images/blog_categories/after_purchase_mobile.png");

export const useHomepageBlogCategories = () => {
    const theme = useTheme();

    return useMemo(() => {
        return [
            {
                title: "W trakcie poszukiwań",
                description:
                    "Podpowiemy Ci, jak sprawnie i efektywnie poruszać się na rynku nieruchomości. Poznaj praktyczne wskazówki dotyczące kupowania mieszkania. Korzystaj z uporządkowanej wiedzy o rynku mieszkaniowym i kredytach. Sprawdź formalności związane z zakupem.",
                pictureSources: [
                    {minWidthPX: 0, src: searchingMobile, height: 296, width: 336},
                    {minWidthPX: 336, src: searchingDesktop, height: 296, width: 552}
                ],
                link: rpAppLink.article.category({categorySlug: "szukam-mieszkania"})
            },
            {
                title: "Po zakupie mieszkania",
                description:
                    "Zainspiruj się praktycznymi wskazówkami dotyczącymi urządzenia mieszkania. Dowiedz się na co zwrócić uwagę przy wyborze usług wykończenia wnętrz, przeprowadzek. Podpowiemy Ci jak mieszkać wygodnie.",
                pictureSources: [
                    {minWidthPX: 0, src: afterPurchaseMobile, height: 296, width: 336},
                    {minWidthPX: 336, src: afterPurchaseDesktop, height: 296, width: 552}
                ],
                link: rpAppLink.article.category({categorySlug: "mam-mieszkanie"})
            }
        ];
    }, [theme]);
};
