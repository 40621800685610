import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";

import {Col, Container, Row} from "@pg-design/grid-module";
import {mt, ph} from "@pg-design/helpers-css";

import {IRPStore} from "../../app/rp_reducer";
import {offerPlural} from "../../offer/utils/offer_plural";
import {fetchHomepageOfferList, resetHomepageOfferList} from "../actions/fetch_homepage_offer_list";
import {sortDefaultOption, sortOptions, SortValue} from "../constants/offer_list";
import {regionDefaultOptionValue} from "../hooks/use_homepage_region_options";
import {getMoreOffersLink} from "../utils/get_more_offers_link";
import {IRegionOption, OffersPanel} from "./OffersPanel/OffersPanel";

export const HomepageRecommendedOffers = () => {
    const dispatch = useDispatch();

    const recommendedOffersCount = useSelector((state: IRPStore) => state.homepage.count);

    const [sortValue, setSortValue] = useState(sortDefaultOption.value);

    useEffect(() => {
        dispatch(fetchHomepageOfferList({region: regionDefaultOptionValue, sort: sortDefaultOption.value}));
    }, []);

    const onFiltersChange = (region: IRegionOption, sort: string) => {
        dispatch(resetHomepageOfferList());

        const query: {[key: string]: number | string} = {region: region.value, sort};

        if (sort === SortValue.PROMOTED) {
            query.with_promotions = "True";
        }

        dispatch(fetchHomepageOfferList(query));

        setSortValue(sort as SortValue);
    };

    const moreOffersCountText = recommendedOffersCount ? `Zobacz jeszcze ${recommendedOffersCount} ${offerPlural(recommendedOffersCount)}` : "";

    return (
        <Container as="section" css={sectionWrapper}>
            <Row>
                <Col css={col}>
                    <OffersPanel
                        getMoreOffersLink={(regionValue: IRegionOption, sortValue: string) => getMoreOffersLink(regionValue, sortValue)}
                        moreOffersText={sortValue === SortValue.RATING ? "Zobacz więcej" : moreOffersCountText}
                        onChange={onFiltersChange}
                        sortInitialValue={sortDefaultOption.value}
                        sortOptions={sortOptions}
                        title="Polecane oferty"
                    />
                </Col>
            </Row>
        </Container>
    );
};

const sectionWrapper = css`
    ${mt(6)};
    overflow: hidden;
`;
const col = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.md}) {
        padding: 0;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        ${ph(3)};
    }
`;
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";

import {Col, Container, Row} from "@pg-design/grid-module";
import {mt, ph} from "@pg-design/helpers-css";

import {IRPStore} from "../../app/rp_reducer";
import {offerPlural} from "../../offer/utils/offer_plural";
import {fetchHomepageOfferList, resetHomepageOfferList} from "../actions/fetch_homepage_offer_list";
import {sortDefaultOption, sortOptions, SortValue} from "../constants/offer_list";
import {regionDefaultOptionValue} from "../hooks/use_homepage_region_options";
import {getMoreOffersLink} from "../utils/get_more_offers_link";
import {IRegionOption, OffersPanel} from "./OffersPanel/OffersPanel";

export const HomepageRecommendedOffers = () => {
    const dispatch = useDispatch();

    const recommendedOffersCount = useSelector((state: IRPStore) => state.homepage.count);

    const [sortValue, setSortValue] = useState(sortDefaultOption.value);

    useEffect(() => {
        dispatch(fetchHomepageOfferList({region: regionDefaultOptionValue, sort: sortDefaultOption.value}));
    }, []);

    const onFiltersChange = (region: IRegionOption, sort: string) => {
        dispatch(resetHomepageOfferList());

        const query: {[key: string]: number | string} = {region: region.value, sort};

        if (sort === SortValue.PROMOTED) {
            query.with_promotions = "True";
        }

        dispatch(fetchHomepageOfferList(query));

        setSortValue(sort as SortValue);
    };

    const moreOffersCountText = recommendedOffersCount ? `Zobacz jeszcze ${recommendedOffersCount} ${offerPlural(recommendedOffersCount)}` : "";

    return (
        <Container as="section" css={sectionWrapper}>
            <Row>
                <Col css={col}>
                    <OffersPanel
                        getMoreOffersLink={(regionValue: IRegionOption, sortValue: string) => getMoreOffersLink(regionValue, sortValue)}
                        moreOffersText={sortValue === SortValue.RATING ? "Zobacz więcej" : moreOffersCountText}
                        onChange={onFiltersChange}
                        sortInitialValue={sortDefaultOption.value}
                        sortOptions={sortOptions}
                        title="Polecane oferty"
                    />
                </Col>
            </Row>
        </Container>
    );
};

const sectionWrapper = css`
    ${mt(6)};
    overflow: hidden;
`;
const col = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.md}) {
        padding: 0;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        ${ph(3)};
    }
`;
