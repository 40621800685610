import React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {Col, Container, Row} from "@pg-design/grid-module";
import {flexAlignCenter, flexJustifyCenter} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";
import {useIsMobile} from "@pg-mono/hooks";

import {useHomepageFeatures} from "../hooks/use_homepage_features";
import {HomepageFeature} from "./HomepageFeature";

export const HomepageFeaturesSection = () => {
    const isMobile = useIsMobile();

    const homepageFeatures = useHomepageFeatures();

    return (
        <Container as="section" fluid css={[homepageFeaturesSection, flexAlignCenter]}>
            <Row>
                <Headline as="h2" align="center" fontFamily="secondary" variant={isMobile ? "headline_2" : "headline_1"}>
                    Jesteśmy skutecznym narzędziem do poszukiwania mieszkania na rynku pierwotnym.
                </Headline>
            </Row>
            <Container>
                <Row css={[features, flexJustifyCenter]}>
                    {homepageFeatures.map((homepageFeatures, index) => {
                        return (
                            <Col noGutters key={index} css={flexJustifyCenter} sm={4} md={6} lg={6}>
                                <Col noGutters md={8} lg={10}>
                                    <HomepageFeature {...homepageFeatures} />
                                </Col>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </Container>
    );
};

const homepageFeaturesSection = (theme: Theme) => css`
    padding: 2.4rem 1.6rem 3.2rem;
    flex-direction: column;
    background-color: ${theme.colors.background["100"]};

    @media (min-width: ${theme.breakpoints.md}) {
        padding: 7.2rem 0 9rem;
    }
`;

const Headline = styled(Text)`
    margin-bottom: 5.6rem;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        max-width: 74.5rem;
        margin-bottom: 10rem;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
        max-width: 74.5rem;
        margin-bottom: 4.8rem;
    }
`;

const features = (theme: Theme) => css`
    row-gap: 6.4rem;

    @media (min-width: ${theme.breakpoints.md}) {
        row-gap: 8rem;
    }
`;
import React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {Col, Container, Row} from "@pg-design/grid-module";
import {flexAlignCenter, flexJustifyCenter} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";
import {useIsMobile} from "@pg-mono/hooks";

import {useHomepageFeatures} from "../hooks/use_homepage_features";
import {HomepageFeature} from "./HomepageFeature";

export const HomepageFeaturesSection = () => {
    const isMobile = useIsMobile();

    const homepageFeatures = useHomepageFeatures();

    return (
        <Container as="section" fluid css={[homepageFeaturesSection, flexAlignCenter]}>
            <Row>
                <Headline as="h2" align="center" fontFamily="secondary" variant={isMobile ? "headline_2" : "headline_1"}>
                    Jesteśmy skutecznym narzędziem do poszukiwania mieszkania na rynku pierwotnym.
                </Headline>
            </Row>
            <Container>
                <Row css={[features, flexJustifyCenter]}>
                    {homepageFeatures.map((homepageFeatures, index) => {
                        return (
                            <Col noGutters key={index} css={flexJustifyCenter} sm={4} md={6} lg={6}>
                                <Col noGutters md={8} lg={10}>
                                    <HomepageFeature {...homepageFeatures} />
                                </Col>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </Container>
    );
};

const homepageFeaturesSection = (theme: Theme) => css`
    padding: 2.4rem 1.6rem 3.2rem;
    flex-direction: column;
    background-color: ${theme.colors.background["100"]};

    @media (min-width: ${theme.breakpoints.md}) {
        padding: 7.2rem 0 9rem;
    }
`;

const Headline = styled(Text)`
    margin-bottom: 5.6rem;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        max-width: 74.5rem;
        margin-bottom: 10rem;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
        max-width: 74.5rem;
        margin-bottom: 4.8rem;
    }
`;

const features = (theme: Theme) => css`
    row-gap: 6.4rem;

    @media (min-width: ${theme.breakpoints.md}) {
        row-gap: 8rem;
    }
`;
