import {friendlyQueryToHouseFilters} from "../../../../constants/house_filter";

export const getHouseFilterByOfferListSubType = (offerListSubFilter?: string) => {
    const houseFilter = offerListSubFilter ? friendlyQueryToHouseFilters[offerListSubFilter] : undefined;

    if (!houseFilter) {
        throw new Error(`Cannot transform offer's sub-type (${offerListSubFilter}) into houseFilter`);
    }

    return houseFilter;
};

export const getHouseFilterByOfferListSubTypeForQuery = (offerListFilter?: string) => {
    const houseFilter = getHouseFilterByOfferListSubType(offerListFilter);
    const [[houseFilterKey, houseFilterValue]] = Object.entries(houseFilter);

    return {
        [houseFilterKey]: [houseFilterValue.toString()]
    };
};
import {friendlyQueryToHouseFilters} from "../../../../constants/house_filter";

export const getHouseFilterByOfferListSubType = (offerListSubFilter?: string) => {
    const houseFilter = offerListSubFilter ? friendlyQueryToHouseFilters[offerListSubFilter] : undefined;

    if (!houseFilter) {
        throw new Error(`Cannot transform offer's sub-type (${offerListSubFilter}) into houseFilter`);
    }

    return houseFilter;
};

export const getHouseFilterByOfferListSubTypeForQuery = (offerListFilter?: string) => {
    const houseFilter = getHouseFilterByOfferListSubType(offerListFilter);
    const [[houseFilterKey, houseFilterValue]] = Object.entries(houseFilter);

    return {
        [houseFilterKey]: [houseFilterValue.toString()]
    };
};
