import {pluralize} from "@pg-mono/string-utils";

import {OfferType} from "../types/OfferType";

export function getOfferTypeName(offerType: OfferType) {
    switch (offerType) {
        case OfferType.FLAT:
            return "mieszkanie";
        case OfferType.HOUSE:
            return "dom";
        case OfferType.COMMERCIAL:
            return "lokal użytkowy";
        default:
            throw new Error("Unknown offer type");
    }
}

export function getOfferTypeNameCapital(offerType: OfferType) {
    switch (offerType) {
        case OfferType.FLAT:
            return "Mieszkanie";
        case OfferType.HOUSE:
            return "Dom";
        case OfferType.COMMERCIAL:
            return "Lokal użytkowy";
        default:
            throw new Error("Unknown offer type");
    }
}

export function getOfferTypeNamePlural(offerType: OfferType) {
    switch (offerType) {
        case OfferType.FLAT:
            return "mieszkania";
        case OfferType.HOUSE:
            return "domy";
        case OfferType.COMMERCIAL:
            return "lokale użytkowe";
        default:
            throw new Error("Unknown offer type");
    }
}

export function getOfferTypeNamePluralCapital(offerType: OfferType) {
    switch (offerType) {
        case OfferType.FLAT:
            return "Mieszkania";
        case OfferType.HOUSE:
            return "Domy";
        case OfferType.COMMERCIAL:
            return "Lokale użytkowe";
        default:
            throw new Error("Unknown offer type");
    }
}

export function getOfferTypeNameWhat(offerType: OfferType) {
    switch (offerType) {
        case OfferType.FLAT:
            return "mieszkania";
        case OfferType.HOUSE:
            return "domu";
        case OfferType.COMMERCIAL:
            return "lokalu użytkowego";
        default:
            throw new Error("Unknown offer type");
    }
}

export function getOfferTypeNamePluralWhat(offerType: OfferType) {
    switch (offerType) {
        case OfferType.FLAT:
            return "mieszkań";
        case OfferType.HOUSE:
            return "domów";
        case OfferType.COMMERCIAL:
            return "lokali użytkowych";
        default:
            throw new Error("Unknown offer type");
    }
}

export function getOfferTypeNamePluralWhatCapital(offerType: OfferType) {
    switch (offerType) {
        case OfferType.FLAT:
            return "Mieszkań";
        case OfferType.HOUSE:
            return "Domów";
        case OfferType.COMMERCIAL:
            return "Lokali użytkowych";
        default:
            throw new Error("Unknown offer type");
    }
}

export function getOfferTypePluralize(amount: number, offerType: number, isApartment = false) {
    switch (offerType) {
        case OfferType.FLAT: {
            const flatPluralize = pluralize(isApartment ? ["apartament", "apartamenty", "apartamentów"] : ["mieszkanie", "mieszkania", "mieszkań"]);
            return flatPluralize(amount);
        }
        case OfferType.HOUSE: {
            const housePluralize = pluralize(["dom", "domy", "domów"]);
            return housePluralize(amount);
        }
        case OfferType.COMMERCIAL: {
            const commercialPluralize = pluralize(["lokal", "lokale", "lokali"]);
            return commercialPluralize(amount);
        }
        default: {
            break;
        }
    }
}

export function getPropertiesCountFieldName(type: OfferType | null) {
    switch (type) {
        case OfferType.FLAT:
            return "properties_flats_count_for_sale";
        case OfferType.HOUSE:
            return "properties_houses_count_for_sale";
        case OfferType.COMMERCIAL:
            return "properties_commercials_count_for_sale";
        default:
            return "properties_all_count_for_sale";
    }
}

export function getOfferTypeEnglishNamePlural(offerType: OfferType) {
    switch (offerType) {
        case OfferType.FLAT:
            return "flats";
        case OfferType.HOUSE:
            return "houses";
        case OfferType.COMMERCIAL:
            return "commercials";
        default:
            throw new Error("Unknown offer type");
    }
}

export const getOfferTypeUrl = (offerType: OfferType) => {
    switch (offerType) {
        case OfferType.FLAT:
            return "nowe-mieszkania";
        case OfferType.HOUSE:
            return "nowe-domy";
        case OfferType.COMMERCIAL:
            return "nowe-lokale-uzytkowe";
        default:
            return "nowe-mieszkania-i-domy";
    }
};

export function getOfferTypeNameWhich(offerType: OfferType) {
    switch (offerType) {
        case OfferType.FLAT:
            return "to mieszkanie";
        case OfferType.HOUSE:
            return "ten dom";
        case OfferType.COMMERCIAL:
            return "ten lokal użytkowy";
        default:
            throw new Error("Unknown offer type");
    }
}
import {pluralize} from "@pg-mono/string-utils";

import {OfferType} from "../types/OfferType";

export function getOfferTypeName(offerType: OfferType) {
    switch (offerType) {
        case OfferType.FLAT:
            return "mieszkanie";
        case OfferType.HOUSE:
            return "dom";
        case OfferType.COMMERCIAL:
            return "lokal użytkowy";
        default:
            throw new Error("Unknown offer type");
    }
}

export function getOfferTypeNameCapital(offerType: OfferType) {
    switch (offerType) {
        case OfferType.FLAT:
            return "Mieszkanie";
        case OfferType.HOUSE:
            return "Dom";
        case OfferType.COMMERCIAL:
            return "Lokal użytkowy";
        default:
            throw new Error("Unknown offer type");
    }
}

export function getOfferTypeNamePlural(offerType: OfferType) {
    switch (offerType) {
        case OfferType.FLAT:
            return "mieszkania";
        case OfferType.HOUSE:
            return "domy";
        case OfferType.COMMERCIAL:
            return "lokale użytkowe";
        default:
            throw new Error("Unknown offer type");
    }
}

export function getOfferTypeNamePluralCapital(offerType: OfferType) {
    switch (offerType) {
        case OfferType.FLAT:
            return "Mieszkania";
        case OfferType.HOUSE:
            return "Domy";
        case OfferType.COMMERCIAL:
            return "Lokale użytkowe";
        default:
            throw new Error("Unknown offer type");
    }
}

export function getOfferTypeNameWhat(offerType: OfferType) {
    switch (offerType) {
        case OfferType.FLAT:
            return "mieszkania";
        case OfferType.HOUSE:
            return "domu";
        case OfferType.COMMERCIAL:
            return "lokalu użytkowego";
        default:
            throw new Error("Unknown offer type");
    }
}

export function getOfferTypeNamePluralWhat(offerType: OfferType) {
    switch (offerType) {
        case OfferType.FLAT:
            return "mieszkań";
        case OfferType.HOUSE:
            return "domów";
        case OfferType.COMMERCIAL:
            return "lokali użytkowych";
        default:
            throw new Error("Unknown offer type");
    }
}

export function getOfferTypeNamePluralWhatCapital(offerType: OfferType) {
    switch (offerType) {
        case OfferType.FLAT:
            return "Mieszkań";
        case OfferType.HOUSE:
            return "Domów";
        case OfferType.COMMERCIAL:
            return "Lokali użytkowych";
        default:
            throw new Error("Unknown offer type");
    }
}

export function getOfferTypePluralize(amount: number, offerType: number, isApartment = false) {
    switch (offerType) {
        case OfferType.FLAT: {
            const flatPluralize = pluralize(isApartment ? ["apartament", "apartamenty", "apartamentów"] : ["mieszkanie", "mieszkania", "mieszkań"]);
            return flatPluralize(amount);
        }
        case OfferType.HOUSE: {
            const housePluralize = pluralize(["dom", "domy", "domów"]);
            return housePluralize(amount);
        }
        case OfferType.COMMERCIAL: {
            const commercialPluralize = pluralize(["lokal", "lokale", "lokali"]);
            return commercialPluralize(amount);
        }
        default: {
            break;
        }
    }
}

export function getPropertiesCountFieldName(type: OfferType | null) {
    switch (type) {
        case OfferType.FLAT:
            return "properties_flats_count_for_sale";
        case OfferType.HOUSE:
            return "properties_houses_count_for_sale";
        case OfferType.COMMERCIAL:
            return "properties_commercials_count_for_sale";
        default:
            return "properties_all_count_for_sale";
    }
}

export function getOfferTypeEnglishNamePlural(offerType: OfferType) {
    switch (offerType) {
        case OfferType.FLAT:
            return "flats";
        case OfferType.HOUSE:
            return "houses";
        case OfferType.COMMERCIAL:
            return "commercials";
        default:
            throw new Error("Unknown offer type");
    }
}

export const getOfferTypeUrl = (offerType: OfferType) => {
    switch (offerType) {
        case OfferType.FLAT:
            return "nowe-mieszkania";
        case OfferType.HOUSE:
            return "nowe-domy";
        case OfferType.COMMERCIAL:
            return "nowe-lokale-uzytkowe";
        default:
            return "nowe-mieszkania-i-domy";
    }
};

export function getOfferTypeNameWhich(offerType: OfferType) {
    switch (offerType) {
        case OfferType.FLAT:
            return "to mieszkanie";
        case OfferType.HOUSE:
            return "ten dom";
        case OfferType.COMMERCIAL:
            return "ten lokal użytkowy";
        default:
            throw new Error("Unknown offer type");
    }
}
