import {useMemo} from "react";
import {useTheme} from "@emotion/react";

import {BrandBuildingCubeIcon, BrandChartIcon, BrandMapMarkersIcon, BrandWeightIcon} from "@pg-design/icons-module";
import {kmAppLink, SiteSlug} from "@pg-mono/km-routes";
import {rpAppLink, rpAppPath} from "@pg-mono/rp-routes";

import {kmUrl} from "../../app/read_rp_environment_variables";
import {bigDataLink} from "../../navigation/constants/links";

export const useHomepageFeatures = () => {
    const theme = useTheme();

    const iconProps = {size: "15", wrapperColor: theme.colors.primary, wrapperSize: "15.5"};

    const recommendationsLinkBase = `${kmUrl}${kmAppLink.site.baseWithParams({site: SiteSlug.RP})}`;

    return useMemo(() => {
        return [
            {
                href: recommendationsLinkBase,
                title: "Inteligentne rekomendacje",
                text: "Przeglądaj mieszkania w <strong>najszerszej bazie</strong> aktualnych i planowanych ofert z rynku pierwotnego. Dzięki <strong>autorskiemu systemowi rekomendowania</strong> nieruchomości podejmiesz dobrą decyzję.",
                icon: <BrandBuildingCubeIcon {...iconProps} />
            },
            {
                href: rpAppPath.comparisonTool,
                title: "Porównywarka ofert",
                text: "Z pomocą naszej <strong>porównywarki ofert</strong> szybko <strong>poznasz i zrozumiesz</strong> różnice między poszczególnymi mieszkaniami oraz w pełni przygotujesz się do rozmowy z deweloperem.",
                icon: <BrandWeightIcon {...iconProps} />
            },
            {
                href: bigDataLink,
                title: "Raporty",
                text: "Obserwuj aktualne <strong>trendy na rynku nieruchomości.</strong> Nasza Platforma <strong>BigData</strong> to najbardziej wiarygodne źródło danych o <strong>średnich cenach mieszkań, zmianach popytu i sprzedaży.</strong>",
                icon: <BrandChartIcon {...iconProps} />
            },
            {
                href: rpAppLink.offer.mapFriendly({friendlySlug: "nowe-mieszkania-i-domy"}),
                title: "Spersonalizowana mapa",
                text: "Jako jedyni na rynku umożliwiamy <strong>pełne personalizowanie mapy.</strong> Dodaj swoje miejsce pracy lub szkołę dzieci, a następnie sprawdź, czy czas dojazdu jest dla Ciebie odpowiedni.",
                icon: <BrandMapMarkersIcon {...iconProps} />
            }
        ];
    }, [iconProps]);
};
import {useMemo} from "react";
import {useTheme} from "@emotion/react";

import {BrandBuildingCubeIcon, BrandChartIcon, BrandMapMarkersIcon, BrandWeightIcon} from "@pg-design/icons-module";
import {kmAppLink, SiteSlug} from "@pg-mono/km-routes";
import {rpAppLink, rpAppPath} from "@pg-mono/rp-routes";

import {kmUrl} from "../../app/read_rp_environment_variables";
import {bigDataLink} from "../../navigation/constants/links";

export const useHomepageFeatures = () => {
    const theme = useTheme();

    const iconProps = {size: "15", wrapperColor: theme.colors.primary, wrapperSize: "15.5"};

    const recommendationsLinkBase = `${kmUrl}${kmAppLink.site.baseWithParams({site: SiteSlug.RP})}`;

    return useMemo(() => {
        return [
            {
                href: recommendationsLinkBase,
                title: "Inteligentne rekomendacje",
                text: "Przeglądaj mieszkania w <strong>najszerszej bazie</strong> aktualnych i planowanych ofert z rynku pierwotnego. Dzięki <strong>autorskiemu systemowi rekomendowania</strong> nieruchomości podejmiesz dobrą decyzję.",
                icon: <BrandBuildingCubeIcon {...iconProps} />
            },
            {
                href: rpAppPath.comparisonTool,
                title: "Porównywarka ofert",
                text: "Z pomocą naszej <strong>porównywarki ofert</strong> szybko <strong>poznasz i zrozumiesz</strong> różnice między poszczególnymi mieszkaniami oraz w pełni przygotujesz się do rozmowy z deweloperem.",
                icon: <BrandWeightIcon {...iconProps} />
            },
            {
                href: bigDataLink,
                title: "Raporty",
                text: "Obserwuj aktualne <strong>trendy na rynku nieruchomości.</strong> Nasza Platforma <strong>BigData</strong> to najbardziej wiarygodne źródło danych o <strong>średnich cenach mieszkań, zmianach popytu i sprzedaży.</strong>",
                icon: <BrandChartIcon {...iconProps} />
            },
            {
                href: rpAppLink.offer.mapFriendly({friendlySlug: "nowe-mieszkania-i-domy"}),
                title: "Spersonalizowana mapa",
                text: "Jako jedyni na rynku umożliwiamy <strong>pełne personalizowanie mapy.</strong> Dodaj swoje miejsce pracy lub szkołę dzieci, a następnie sprawdź, czy czas dojazdu jest dla Ciebie odpowiedni.",
                icon: <BrandMapMarkersIcon {...iconProps} />
            }
        ];
    }, [iconProps]);
};
