import {RequestState} from "@pg-mono/request-state";

export function getQueryStatus(requestState: RequestState) {
    return {
        isInitialized: requestState !== RequestState.None,
        isLoading: requestState === RequestState.Waiting,
        isSuccess: requestState === RequestState.Success,
        isError: requestState === RequestState.Error
    };
}

export type IRpQueryStatus = ReturnType<typeof getQueryStatus>;
import {RequestState} from "@pg-mono/request-state";

export function getQueryStatus(requestState: RequestState) {
    return {
        isInitialized: requestState !== RequestState.None,
        isLoading: requestState === RequestState.Waiting,
        isSuccess: requestState === RequestState.Success,
        isError: requestState === RequestState.Error
    };
}

export type IRpQueryStatus = ReturnType<typeof getQueryStatus>;
