import React, {CSSProperties} from "react";
import {animated} from "@react-spring/web";

interface IProps {
    children: React.ReactNode;
    animationRef?: React.RefObject<HTMLDivElement>;
    style: {[key: string]: unknown};
}

export const Animated: React.FC<IProps> = (props) => {
    return (
        <>
            {props.animationRef ? (
                <animated.div ref={props.animationRef} style={props.style as CSSProperties}>
                    {props.children}
                </animated.div>
            ) : (
                <animated.div style={props.style as CSSProperties}>{props.children}</animated.div>
            )}
        </>
    );
};
import React, {CSSProperties} from "react";
import {animated} from "@react-spring/web";

interface IProps {
    children: React.ReactNode;
    animationRef?: React.RefObject<HTMLDivElement>;
    style: {[key: string]: unknown};
}

export const Animated: React.FC<IProps> = (props) => {
    return (
        <>
            {props.animationRef ? (
                <animated.div ref={props.animationRef} style={props.style as CSSProperties}>
                    {props.children}
                </animated.div>
            ) : (
                <animated.div style={props.style as CSSProperties}>{props.children}</animated.div>
            )}
        </>
    );
};
