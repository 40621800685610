import {useTheme} from "@emotion/react";

export const useThemeNumericBreakpoints = () => {
    const theme = useTheme();
    return theme.breakpoints.numeric;
};
import {useTheme} from "@emotion/react";

export const useThemeNumericBreakpoints = () => {
    const theme = useTheme();
    return theme.breakpoints.numeric;
};
