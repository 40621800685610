import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {flexAlignCenter, mb} from "@pg-design/helpers-css";
import {getThemeBreakpoint} from "@pg-design/styles-strings";
import {Text} from "@pg-design/text-module";
import {useTranslation} from "@pg-mono/i18n";

import {useAnimatedHomepageHeader} from "../hooks/use_animated_homepage_header";

export const HomepageHeaderContent = () => {
    const slides = ["Największa baza nowych mieszkań i domów", "Wyszukaj ofertę dla siebie"];
    const translatedSlides = useTranslation(slides);
    const headline = useAnimatedHomepageHeader(translatedSlides ? translatedSlides : slides);

    return (
        <div css={homepageHeaderContent}>
            <Headline as="h1" align="center" fontFamily="secondary" css={headerStyle}>
                {headline}
                <div css={[subHeadline]}>Ponad 85 000 sprawdzonych ofert od 750 deweloperów</div>
            </Headline>
        </div>
    );
};

const homepageHeaderContent = css`
    ${flexAlignCenter};
    flex-direction: column;
    position: relative;
`;

const Headline = styled(Text)`
    color: #fff;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 4.32rem;
    z-index: 1;
    ${mb(8)};

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        font-size: 7.2rem;
        line-height: 8.64rem;
        ${mb(4)}
    }
`;

const headerStyle = css`
    font-size: 3.6rem;
    line-height: 4.32rem;

    @media (min-width: ${getThemeBreakpoint().md}) {
        font-size: 5.4rem;
        line-height: 6.48rem;
    }
`;

const subHeadline = css`
    font-weight: 400;
    font-size: 2.8rem;
    line-height: 3.36rem;

    @media (min-width: ${getThemeBreakpoint().md}) {
        font-size: 4.2rem;
        line-height: 5.04rem;
    }
`;
import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {flexAlignCenter, mb} from "@pg-design/helpers-css";
import {getThemeBreakpoint} from "@pg-design/styles-strings";
import {Text} from "@pg-design/text-module";
import {useTranslation} from "@pg-mono/i18n";

import {useAnimatedHomepageHeader} from "../hooks/use_animated_homepage_header";

export const HomepageHeaderContent = () => {
    const slides = ["Największa baza nowych mieszkań i domów", "Wyszukaj ofertę dla siebie"];
    const translatedSlides = useTranslation(slides);
    const headline = useAnimatedHomepageHeader(translatedSlides ? translatedSlides : slides);

    return (
        <div css={homepageHeaderContent}>
            <Headline as="h1" align="center" fontFamily="secondary" css={headerStyle}>
                {headline}
                <div css={[subHeadline]}>Ponad 85 000 sprawdzonych ofert od 750 deweloperów</div>
            </Headline>
        </div>
    );
};

const homepageHeaderContent = css`
    ${flexAlignCenter};
    flex-direction: column;
    position: relative;
`;

const Headline = styled(Text)`
    color: #fff;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 4.32rem;
    z-index: 1;
    ${mb(8)};

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        font-size: 7.2rem;
        line-height: 8.64rem;
        ${mb(4)}
    }
`;

const headerStyle = css`
    font-size: 3.6rem;
    line-height: 4.32rem;

    @media (min-width: ${getThemeBreakpoint().md}) {
        font-size: 5.4rem;
        line-height: 6.48rem;
    }
`;

const subHeadline = css`
    font-weight: 400;
    font-size: 2.8rem;
    line-height: 3.36rem;

    @media (min-width: ${getThemeBreakpoint().md}) {
        font-size: 4.2rem;
        line-height: 5.04rem;
    }
`;
