import {css} from "@emotion/react";

import {calculateRemSize, DEFAULT_SIZE, Size} from "./utils/rem";

// grid-gap
export const gridG = (size: Size = DEFAULT_SIZE) => css`
    grid-gap: ${calculateRemSize(size)};
`;

export const gridCG = (size: Size = DEFAULT_SIZE) => css`
    grid-column-gap: ${calculateRemSize(size)};
`;

export const gridRG = (size: Size = DEFAULT_SIZE) => css`
    grid-row-gap: ${calculateRemSize(size)};
`;
import {css} from "@emotion/react";

import {calculateRemSize, DEFAULT_SIZE, Size} from "./utils/rem";

// grid-gap
export const gridG = (size: Size = DEFAULT_SIZE) => css`
    grid-gap: ${calculateRemSize(size)};
`;

export const gridCG = (size: Size = DEFAULT_SIZE) => css`
    grid-column-gap: ${calculateRemSize(size)};
`;

export const gridRG = (size: Size = DEFAULT_SIZE) => css`
    grid-row-gap: ${calculateRemSize(size)};
`;
