import React from "react";

import {LeadModal} from "../../lead/components/lead_modal/LeadModal";
import {HomepageBlogSection} from "../components/HomepageBlogSection";
import {HomepageFeaturesSection} from "../components/HomepageFeaturesSection";
import {HomepageHeader} from "../components/HomepageHeader";
import {HomepageMarketplacePromoSection} from "../components/HomepageMarketplacePromoSection";
import {HomepageRecommendedOffers} from "../components/HomepageRecommendedOffers";
import {HomepageWhyWeSection} from "../components/HomepageWhyWeSection";
import {SchemaHomepage} from "../components/SchemaHomepage";

export const HomepageView = () => {
    return (
        <div>
            <HomepageHeader />
            <HomepageRecommendedOffers />
            <HomepageFeaturesSection />
            <HomepageBlogSection />
            <HomepageMarketplacePromoSection />
            <HomepageWhyWeSection />
            <LeadModal />
            <SchemaHomepage />
        </div>
    );
};
import React from "react";

import {LeadModal} from "../../lead/components/lead_modal/LeadModal";
import {HomepageBlogSection} from "../components/HomepageBlogSection";
import {HomepageFeaturesSection} from "../components/HomepageFeaturesSection";
import {HomepageHeader} from "../components/HomepageHeader";
import {HomepageMarketplacePromoSection} from "../components/HomepageMarketplacePromoSection";
import {HomepageRecommendedOffers} from "../components/HomepageRecommendedOffers";
import {HomepageWhyWeSection} from "../components/HomepageWhyWeSection";
import {SchemaHomepage} from "../components/SchemaHomepage";

export const HomepageView = () => {
    return (
        <div>
            <HomepageHeader />
            <HomepageRecommendedOffers />
            <HomepageFeaturesSection />
            <HomepageBlogSection />
            <HomepageMarketplacePromoSection />
            <HomepageWhyWeSection />
            <LeadModal />
            <SchemaHomepage />
        </div>
    );
};
